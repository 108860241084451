import React from "react";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import marketing from '../../../../services/marketing';
import AdobeScript from "../../../../services/adobeScript";

function Mail() {
    const setting = useStoreState((state) => state.siteSettingsModel);
    const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
    const utms = new marketing()
    const adobe = new AdobeScript();
    const WaterfallEvents = { "ACTIVATION_PAYMENT" : 32 };
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const history = useHistory();

    return (
        <div className="row card-box m-0">
            <div className="col-12 d-flex flex-column align-items-start">
                <p className="pr-0 pl-0">
                    Mail your check or money order to:
                </p>
                <p className="pr-0 pl-0 font-weight-bold">
                    {setting.brandSettings.BankName}, Continental Finance<br />
                    PO Box 8099,<br />
                    Newark, DE 19714-8099.
                </p>
                <p className="pr-0 pl-0">
                    Please include your Reference Number in the memo section of your check or money order. Allow 7 to 10 days mail time for your payment to be received. 
                    Please note that by using check to pay your processing fee there will be a delay of up to 10 days before your account can be opened to ensure that the funds clear.
                </p>
                <p className="pr-0 pl-0">
                    To avoid any delay please consider choosing the card payment option.
                </p>
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
                <hr className='w-100' />
                <p className="font-italic font-weight-bold">I'm making my payment via Check or Money Order</p>
                <button
                    variant="primary"
                    className="pay-processing-button align-items-center"
                    style={{ backgroundColor: setting.brandSettings.BannerBgColor, border: "1px solid transparent" }}
                    onClick={() => {
                        sessionStorage.setItem("Payment", 0);
                        adobe.event('checkMoney_select')
                        history.push("/enjoy");
                        utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
                    }}
                >
                    Select
                </button>
            </div>
        </div>
    );
}

export default Mail;